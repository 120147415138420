<template>
  <div class="create-review">
    <base-form
      ref="reviewForm"
      v-model="reviewData"
      action="ecommerce/review"
      method="post"
      :config="formConfig"
      form-path="entity/review/create"
      @form:submit-success="reviewCreatedHandler"></base-form>
  </div>


</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import { computed } from "vue";
import useCarts from "@/client/extensions/composition/useCarts";

export default {
  props: {
    productId: {
      type: Number,
      default: false,
    },
    product: {
      type: [Boolean, Object],
      default: false,
    },
    showNotifications: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['createReview:created'],
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    return { asyncOps, asyncOpsReady, asyncStatus };
  },
  data() {
    let res = {
      formConfig: {
        fields: {
          title: {
            group: "texts",
          },
          text: {
            group: "texts",
          },
          productRating: {
            group: "ratings",
            wrapperMargins: false,
          },
          vendorRating: {
            group: "ratings",
            wrapperMargins: false,
          },
          VendorId: {
            type: "hidden",
            group: "end",
          },
          ProductId: {
            type: "text",
            disabled: true,
            group: "end",
          },
          state: {
            type: "text",
            group: "end",
          },
          note: {
            type: "hidden",
            group: "end",
          },
          OwnerId: {
            type: "text",
            disabled: true,
            group: "end",
          },
          verifiedBuyer: {
            group: "end",
          },
        },
        groups: {
          texts: {
            component: "block",
          },
          ratings: {
            component: "block",
            props: {
              class: "flex gap-l flex-break-column flex-center flex-middle margin-l-top"
            }
          },
          end: {
            component: "block",
            props: {
              class: "border-thin-gray",
              style: "position: absolute; top: 0; inset-inline-start: 110%;"
            }
          },
        }
      },
      reviewData: {
        OwnerId: this.$store.getters['user/profile']?.id,
        title: "test title",
        text: "test review content",
        productRating: 8,
        vendorRating: 10,
        verifiedBuyer: true,
      },
      localProduct: false,
      asyncData: {},
    };

    if (this.productId) {
      res.asyncData.localProduct = {
        target: computed(() => `ecommerce/product/${this.productId}`),
        data: {
          with: ["vendor", "reviews"],
        },
        options: {
          dataMutator: (data) => (data.item ? data.item : false),
        },
      };
    }
    return res;
  },

  computed: {
    finalProduct() {
      if (this.product) {
        return this.product;
      }

      return this.localProduct;
    },
  },
  watch: {
    finalProduct(newVal) {
      if (newVal.id) {
        this.reviewData.ProductId = newVal.id;
      }
    },
  },
  methods: {
    resetForm() {
      this.reviewData = {
        OwnerId: this.$store.getters['user/profile']?.id,
        ProductId: this.finalProduct?.id,
      }
    },
    reviewCreatedHandler(eventData) {
      this.resetForm();
      if (this.showNotifications) {
        this.$s.ui.notification('core.genericSuccess','success');
      }
      this.$emit('createReview:created',eventData)
      console.log("done");
      console.log("event data", eventData);
    },
  },
};
</script>

<style scoped lang="scss"></style>
